import React from 'react';
import { graphql, usePreloadedQuery, type EntryPointProps } from 'react-relay';
import { DashboardsContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-dashboards/src/common/ui/content/DashboardsContent.tsx';
import type { DashboardsContentViewQuery } from '@atlassian/jira-relay/src/__generated__/DashboardsContentViewQuery.graphql';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';

type Queries = { queryRef: DashboardsContentViewQuery };

export function DashboardsContentViewQuery({
	queries: { queryRef },
}: EntryPointProps<Queries, {}, Partial<EntryPointRouteParams>, {}>) {
	const data = usePreloadedQuery<DashboardsContentViewQuery>(
		graphql`
			query DashboardsContentViewQuery($cloudId: ID!) @preloadable {
				...DashboardsContent
			}
		`,
		queryRef,
	);

	return <DashboardsContent queryRef={data} />;
}
